import type { Layout, SortByLevel, ColumnProperties, ColumnsLayout } from 'o365.modules.DataObject.Layout.ts';
import type { GroupByDefinition } from 'o365.modules.DataObject.GroupBy.ts';

export default class LayoutObject {
    private _columns: LayoutColumnsObject;
    private _filter: LayoutProperty<string>
    private _sortBy: LayoutProperty<string>;
    private _groupBy: LayoutProperty<string>;

    private _dependencyLayout: DependencyLayout;

    id: number|null;
    originalId?: number|null;
    contextId?: number|null;
    personId?: number|null;
    updated?: string|null;
    isDefault?: boolean|null;
    isShared?: boolean|null;
    _name: LayoutProperty<string>;
    _description: LayoutProperty<string>;

    get name() { return this._name.value; }
    set name(value: string|undefined|null) { this._name.value = value; }

    get description() { return this._description.value; }
    set description(value: string|undefined|null) { this._description.value = value; }

    get dependencyId() { return this._dependencyLayout?.id; }

    constructor(
        layout?: Layout,
        metaInfo?: {
            id?: number|null;
            contextId?: number|null;
            personId?: number|null;
            updated?: string|null;
            name?: string|null;
            description?: string|null;
            isDefault?: boolean;
            dependancyLayoutL?: DependencyLayout;
            isShared?: boolean;
        },
    ) {
        if (!layout) { layout = {}; }
        if (!metaInfo) { metaInfo = {}; }

        this.id = metaInfo.id ?? null;
        this.contextId = metaInfo.contextId;
        this.personId = metaInfo.personId;
        this.updated = metaInfo.updated;
        this.isDefault = metaInfo.isDefault;
        this.isShared = metaInfo.isShared ?? false;
        this._name = new LayoutProperty(metaInfo.name);
        this._description = new LayoutProperty(metaInfo.description);

        this._columns = new LayoutColumnsObject(layout.columns);
        this._filter = new LayoutProperty(layout.filter ?? undefined);
        this._sortBy = new LayoutProperty(layout.sortBy ? JSON.stringify(layout.sortBy) : undefined);
        this._groupBy = new LayoutProperty(layout.groupBy ? JSON.stringify(layout.groupBy) : undefined);
    }

    set filter(newValue: string|undefined|null) { this._filter.value = newValue; }
    get filter() { return this._filter.value; }

    set sortBy(newValue: Array<SortByLevel>|null|undefined) {
        const value = newValue
            ? JSON.stringify(newValue)
            : newValue === null
                ? null
                : undefined;
        this._sortBy.value = value;
    }
    get sortBy() {
        if (this._sortBy.value) {
            return JSON.parse(this._sortBy.value);
        } else {
            return this._sortBy.value === null ? null : undefined;
        }
    }

    set groupBy(newValue: Array<Array<GroupByDefinition>>|null|undefined) {
        const value = newValue
            ? JSON.stringify(newValue)
            : newValue === null
                ? null
                : undefined;
        this._groupBy.value = value;
    }
    get groupBy() {
        if (this._groupBy.value) {
            return JSON.parse(this._groupBy.value);
        } else {
            return this._groupBy.value === null ? null : undefined;
        }
    }

    get columns() { return this._columns; }

    get hasChanges() {
        return this._groupBy.hasChanges || this._sortBy.hasChanges || this._filter.hasChanges || this._columns.hasChanges;
    }

    get changes() {
        const changes: Layout = {};
        if (this._columns.hasChanges) {
            changes.columns = this.columns.changes;
        }
        if (this._filter.hasChanges) {
            changes.filter = this.filter;
        }
        if (this._sortBy.hasChanges) {
            changes.sortBy = this.sortBy;
        }
        if (this._groupBy.hasChanges) {
            changes.groupBy = this.groupBy;
        }
        return changes;
    }

    commit() {
        this._columns.commit();
        this._filter.commit();
        this._sortBy.commit();
        this._groupBy.commit();
    }

    cancel() {
        this._columns.cancel();
        this._filter.cancel();
        this._sortBy.cancel();
        this._groupBy.cancel();
    }

    setupDependencies(id: number, contextId: number, layout: Layout) {
        this._dependencyLayout = {
            id: id,
            contextId: contextId,
            layout: layout
        };
    }

    getLayout(): Layout {
        const layout: Layout = {};

        const columnsLayout = this._columns.getLayout();
        if (columnsLayout) {
            layout.columns = columnsLayout;
        }
        if (this._filter.oldValue !== undefined) {
            layout.filter = this.filter;
        }
        if (this._sortBy.oldValue !== undefined) {
            layout.sortBy = this.sortBy;
        }
        if (this._groupBy.oldValue !== undefined) {
            layout.groupBy = this.groupBy;
        }

        return layout;
    }

    getLayoutWithDependencies() {
        const layout: Layout = {};

        layout.columns = this.getColumnsLayoutWithDependencies();
        layout.filter = this.getFilterWithDependencies();
        layout.sortBy = this.getSortByWithDependencies();
        layout.groupBy = this.getGroupByWithDependencies();

        return layout;
    }

    getColumnsLayoutWithDependencies() {
        const layout = {};
        if (this._dependencyLayout?.layout?.columns) {
            Object.keys(this._dependencyLayout.layout.columns).forEach((colId) => {
                if (!layout[colId]) { layout[colId] = {}; }
                if (this._dependencyLayout.layout.columns == null) { return; }
                Object.keys(this._dependencyLayout.layout.columns[colId]).forEach((key) => {
                    if (this._dependencyLayout.layout.columns == null) { return; }
                    layout[colId][key] = this._dependencyLayout.layout.columns[colId][key];
                });
            });
        }

        const columnsLayout = this._columns.getLayout();
        if (columnsLayout) {
            Object.keys(columnsLayout).forEach((colId) => {
                if (!layout[colId]) { layout[colId] = {}; }
                Object.keys(columnsLayout[colId]).forEach((key) => {
                    layout[colId][key] = columnsLayout[colId][key];
                });
            });
        }

        if (Object.keys(layout).length > 0) { return layout; }
    }

    getFilterWithDependencies() {
        return this._filter.oldValue === undefined ? this._dependencyLayout?.layout?.filter : this._filter.oldValue;
    }
    getSortByWithDependencies() {
        return this._sortBy.oldValue === undefined ? this._dependencyLayout?.layout?.sortBy : this.sortBy;
    }
    getGroupByWithDependencies() {
        return this._groupBy.oldValue === undefined ? this._dependencyLayout?.layout?.groupBy : this.groupBy;
    }

    /**
     * Helper function to transform OrgUint layout into a Personal OrgUnit layout
     */
    tranfsormToPersonal(personId: number) {
        if (this.id == null || this.contextId == null) { return; }
        this.setupDependencies(this.id, this.contextId, this.getLayout());
        this.originalId = this.id;
        this.id = null;
        this.personId = personId;
        this._columns = new LayoutColumnsObject();
        this._filter = new LayoutProperty<string>(undefined);
        this._sortBy = new LayoutProperty<string>(undefined);
        this._groupBy = new LayoutProperty<string>(undefined);
    }
}

class LayoutProperty<T> {
    private _value: T|undefined|null;
    private _oldValue: T|undefined|null;
    hasChanges: boolean = false;

    constructor(initialValue: T|undefined|null) {
        this._value = initialValue;
        this._oldValue = initialValue;
    }

    get oldValue() {
        return this._oldValue;
    }

    get value() {
        return this._value;
    }

    set value(newValue) {
        if (newValue !== this._value) {
            if (newValue === this._oldValue) {
                this.hasChanges = false;
            } else {
                this._value = newValue;
                this.hasChanges = true;
            }
        }
    }

    cancel() {
        if (this.hasChanges) {
            this._value = this._oldValue;
            this.hasChanges = false;
        }
    }

    commit() {
        if (this.hasChanges) {
            this._oldValue = this._value;
            this.hasChanges = false;
        }
    }
}

class LayoutColumnsObject {
    _columns: { [key: string]: LayoutColumnProperties };

    constructor(initialColumns?: { [key: string]: ColumnProperties } | null) {
        this._columns = {};
        if (initialColumns) {
            Object.keys(initialColumns).forEach(key => {
                this._columns[key] = new LayoutColumnProperties(initialColumns[key]);
            });
        }
    }

    get keys() { return Object.keys(this._columns); }

    get(colId: string) {
        if (!colId) { return; }

        if (!this._columns[colId]) { this._columns[colId] = new LayoutColumnProperties(); }
        return this._columns[colId];
    }

    get hasChanges() {
        return Object.entries(this._columns).some(([_key, properties]) => {
            return properties.hasChanges
        });
    }

    get changes() {
        return Object.entries(this._columns).reduce((changes, [colId, column]) => {
            if (column.hasChanges) {
                changes[colId] = column.changes;
            }
            return changes;
        }, {});
    }

    cancel() {
        Object.entries(this._columns).forEach(([_key, properties]) => {
            properties.cancel();
        });
    }

    commit() {
        let columnsToDelete: string[] = [];
        Object.entries(this._columns).forEach(([key, properties]) => {
            properties.commit();
            if (properties.isEmpty) {
                columnsToDelete.push(key);
            }
        });
        columnsToDelete.forEach(key => {
            delete this._columns[key];
        });
    }

    getLayout(): ColumnsLayout|null {
        let layout: ColumnsLayout|null = null;
        if (this.keys.length > 0) {
            const layoutColumns = {};
            this.keys.forEach((colId) => {
                const properties: ColumnProperties = {};
                const column = this.get(colId);
                if (column?.width.oldValue !== undefined) {
                    properties.width = column.width.oldValue;
                }
                if (column?.pinned.oldValue !== undefined) {
                    properties.pinned = column.pinned.oldValue;
                }
                if (column?.order.oldValue !== undefined) {
                    properties.order = column.order.oldValue;
                }
                if (column?.hide.oldValue !== undefined) {
                    properties.hide = column.hide.oldValue;
                }
                if (column?.hideFromChooser.oldValue !== undefined) {
                    properties.hideFromChooser = column.hideFromChooser.oldValue;
                }

                if (Object.keys(properties).length > 0) {
                    layoutColumns[colId] = properties;
                }
            });
            if (Object.keys(layoutColumns).length > 0) {
                layout = layoutColumns;
            }
        }
        return layout;
    }

}


class LayoutColumnProperties {
    width: LayoutProperty<number>;
    pinned: LayoutProperty<string>;
    hide: LayoutProperty<boolean>;
    order: LayoutProperty<number>;
    hideFromChooser: LayoutProperty<boolean>;

    constructor(initialValues?: ColumnProperties) {
        if (!initialValues) { initialValues = {}; }
        this.width = new LayoutProperty(initialValues.width);
        this.pinned = new LayoutProperty(initialValues.pinned);
        this.hide = new LayoutProperty(initialValues.hide);
        this.order = new LayoutProperty(initialValues.order);
        this.hideFromChooser = new LayoutProperty(initialValues.hideFromChooser);
    }

    get hasChanges() {
        return this.width.hasChanges ||
            this.pinned.hasChanges ||
            this.hide.hasChanges ||
            this.order.hasChanges ||
            this.hideFromChooser.hasChanges;
    }

    get changes() {
        const result: ColumnProperties = {};
        if (this.width.hasChanges) { result.width = this.width.value; }
        if (this.pinned.hasChanges) { result.pinned = this.pinned.value; }
        if (this.hide.hasChanges) { result.hide = this.hide.value; }
        if (this.order.hasChanges) { result.order = this.order.value; }
        if (this.hideFromChooser.hasChanges) { result.hideFromChooser = this.hideFromChooser.value; }
        return result;
    }

    get isEmpty() {
        return this.width.value === undefined
            && this.pinned === undefined
            && this.hide === undefined
            && this.order === undefined
            && this.hideFromChooser === undefined;
    }

    cancel() {
        this.width.cancel();
        this.pinned.cancel();
        this.hide.cancel();
        this.order.cancel();
        this.hideFromChooser.cancel();
    }

    commit() {
        this.width.commit();
        this.pinned.commit();
        this.hide.commit();
        this.order.commit();
        this.hideFromChooser.commit();
    }

}

interface DependencyLayout {
    id: number,
    contextId: number,
    layout: Layout
}